<template>
  <v-container style="max-width: 800px; position: relative;">
    <Header />
    <ContactInfo />
    <Footer />
  </v-container>
</template>

<script>
import Header from '../components/Header.vue'
import ContactInfo from '../components/ContactInfo.vue'
import Footer from '../components/Footer.vue'

export default {
    components: {
        Header,
        ContactInfo,
        Footer
    },
    layout: 'report',
    mounted: function() {
      if(window.location.host == "vihjaa.fi") {
        window.location.replace("https://vihjaa.fi");
      }
    }
}
</script>

