<template>
    <footer class="text-center" style="margin-top: 150px;">
        <p>{{ $t('footer.text')}}</p>
    </footer>
</template>

<style scoped>
</style>

<script>
export default {
}
</script>
